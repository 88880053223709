import React, { useEffect, useRef, useState } from 'react';
import useScript from './useScript';


const TapPaymentCard = ({ language, color = 'gray', placeholderColor = 'black', setPaymentCard, paymentApiKey }) => {
  const [isSavingCard, setIsSavingCard] = useState(false);
  const [tapPaymentDidLoad, setTapPaymentDidLoad] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [isRendered, setIsRendered] = useState(false);
  const [blueBirdScriptLoad, setBlueBirdScriptLoad] = useState(false);
  const [tapScriptLoad, setTapScriptLoad] = useState(false);
  useScript("https://cdnjs.cloudflare.com/ajax/libs/bluebird/3.3.4/bluebird.min.js", setBlueBirdScriptLoad);
  useScript("https://secure.gosell.io/js/sdk/tap.min.js", setTapScriptLoad);

  const tapFormContainer = useRef(null);
  const isMountedRef = useRef(null);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    let submitCard;
    const tapForm = tapFormContainer.current;

    if (blueBirdScriptLoad && tapScriptLoad && !isRendered) {
      const tap = window.Tapjsli(paymentApiKey);
      const tapElements = tap.elements({});

      const style = {
        base: {
          color,
          lineHeight: '22px',
          fontFamily: 'sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '15px',
          '::placeholder': {
            color: placeholderColor,
            fontSize: '15px',
          },
        },
        invalid: {
          color: 'red',
        },
      };

      const labels = {
        cardNumber: language === 'ar' ? "رقم البطاقة" : "Card Number",
        expirationDate: "MM/YY",
        cvv: "CVV",
        cardHolder: language === 'ar' ? "اسم حامل البطاقة" : "Card Holder's Name",
      };

      const paymentOptions = {
        currencyCode:["KWD","USD","SAR", "QAR", "OMR"],
        labels,
        TextDirection: language === 'ar' ? 'rtl' : 'ltr',
      };

      const tapCardElement = tapElements.create('card', { style }, paymentOptions);
      tapCardElement.mount('#tap-card-container');

      const changeHandler = event => {
        if (isMountedRef.current) {
          if (event.error) {
            setErrorMessage(event?.error?.message);
          } else {
            setErrorMessage(null);
          }
        }
      };

      submitCard = async event => {
        event.preventDefault();
        if (isMountedRef.current) {
          setIsSavingCard(true);
          await tap.createToken(tapCardElement).then(result => {
            if (result.error) {
              setErrorMessage(result.error.message);
            } else {
              setPaymentCard(JSON.stringify(result));
            }
            setIsSavingCard(false);
          });
        }
      };
      tapForm.addEventListener('submit', submitCard);

      if (!tapPaymentDidLoad && isMountedRef.current) {
        tapCardElement.addEventListener('change', changeHandler);
        setTapPaymentDidLoad(true);
      }

      if (isMountedRef.current) setIsRendered(true);
    }
  }, [
    blueBirdScriptLoad,
    tapScriptLoad,
    isRendered,
    setIsSavingCard,
    setPaymentCard,
    tapPaymentDidLoad,
    setTapPaymentDidLoad,
    language,
    color,
    placeholderColor,
    paymentApiKey
  ]);

  console.log(isSavingCard);

  return (
    <>
      <form ref={tapFormContainer} id="tap-form-container">
        <div id="tap-card-container" />
        {!!errorMessage && (
          <p>{errorMessage}</p>
        )}
      </form>
      {!isSavingCard && (
        <button type="submit" form='tap-form-container' id="tap-btn">Submit</button>
      )}
    </>

  );
};

export default TapPaymentCard;
